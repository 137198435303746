import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import Shell from "../layout/shell";
import rightArrow from "../assets/images/right-arrow.svg";

import * as styles from "./caseStudy.module.scss";
import { TransitionBlock } from "../components/transitionBlock";

const CaseStudy: React.FunctionComponent<PageProps<Queries.CaseStudyQuery>> = ({
  data,
}) => {
  const caseStudy = data.caseStudy?.data;
  if (!caseStudy) {
    return null;
  }
  const hero = caseStudy.hero && getImage(caseStudy.hero?.gatsbyImageData);

  return (
    <Shell>
      <div className="fixed-width">
        <h1 className={styles.caseStudyHeader}>{caseStudy?.title}</h1>
        <p className="intro">{caseStudy.intro}</p>
        <div className={styles.caseStudyMeta}>
          <div>
            <strong>Sector: </strong>
            <span>{caseStudy.sector}</span>
          </div>
          <div>
            <strong>Deliverables: </strong>
            <span>{caseStudy.deliverables}</span>
          </div>
          {caseStudy.role && (
            <div>
              <strong>Role: </strong>
              <span>{caseStudy.role}</span>
            </div>
          )}
          {caseStudy.live_site_url?.url && (
            <div>
              <strong>Live site: </strong>
              <a
                rel="noopener"
                href={caseStudy.live_site_url?.url}
                target="_blank"
              >
                {caseStudy.live_site_url?.url}
              </a>
            </div>
          )}
        </div>
        {hero && (
          <GatsbyImage
            className={styles.hero}
            image={hero}
            alt={caseStudy.hero.alt || ""}
          />
        )}
        <div className={styles.infoBlocks}>
          <div>
            <h3>{`${caseStudy.story_prefix} Story`}</h3>
            <p>{caseStudy.story}</p>
          </div>
          <div>
            <h3>Discover & Define</h3>
            <p>{caseStudy.discover_define}</p>
          </div>
          <div>
            <h3>Develop & Deliver</h3>
            <p>{caseStudy.develop_deliver}</p>
          </div>
        </div>
        <div>
          {caseStudy.images?.map((imageItem) => {
            const image =
              imageItem?.image && getImage(imageItem.image.gatsbyImageData);
            return (
              <TransitionBlock className={styles.caseStudyImage}>
                {image && (
                  <GatsbyImage image={image} alt={imageItem.image.alt || ""} />
                )}
                {imageItem?.image?.alt && <p>{imageItem.image.alt}</p>}
              </TransitionBlock>
            );
          })}
        </div>
        <div className={styles.caseStudyFooter}>
          <Link to="/work" className={styles.viewWorkLink}>
            <img src={rightArrow} />
            View all work
          </Link>
        </div>
      </div>
    </Shell>
  );
};

export const query = graphql`
  query CaseStudy($uid: String!) {
    caseStudy: prismicCaseStudy(uid: { eq: $uid }) {
      uid
      data {
        title
        intro
        sector
        deliverables
        role
        live_site_url {
          url
        }
        hero {
          gatsbyImageData
          alt
        }
        story_prefix
        story
        discover_define
        develop_deliver
        images {
          image {
            gatsbyImageData
            alt
          }
        }
      }
    }
  }
`;

export default CaseStudy;
