import { useInViewport } from "@rocketmakers/armstrong-edge";
import React, { HtmlHTMLAttributes } from "react";

export const TransitionBlock: React.FunctionComponent<
  React.PropsWithChildren<HtmlHTMLAttributes<HTMLDivElement>>
> = ({ children, ...rest }) => {
  const container = React.useRef<HTMLDivElement>(null);

  const inViewport = useInViewport(container, {
    rootMargin: "50px",
    once: true,
  });
  return (
    <div data-visible={inViewport} ref={container} {...rest}>
      {children}
    </div>
  );
};
